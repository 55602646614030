import type { RouteLocationNormalized, RouteMeta } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import DashboardView from "../views/DashboardView.vue";
import AuthView from "../views/auth/AuthView.vue";
import { useAuthStore } from "@/stores/useAuthStore";
import type { MoonRouteMeta } from "@/models/moonRouteMeta";
import { RouteName } from "@/router/routeName";
import routeNameToMeta from "@/router/routeNameToMeta";
import hasPermissions from "@/functional/permissions/hasPermissions";
import { USER_HAS_BEEN_ONBOARDED } from "@/models/userHasBeenOnboarded";

const routeRequiresAuth = (toMeta: MoonRouteMeta): boolean => {
  return toMeta.requiresAuth === undefined ? true : toMeta.requiresAuth;
};

const userHasPermissionToAccessAuthenticatedRoute = (
  currentUserPermissions: string[],
  toMeta: MoonRouteMeta,
): boolean => {
  const requiredPermissions = toMeta.requiredPermissions;
  return hasPermissions(currentUserPermissions, requiredPermissions);
};

const getRouteMeta = (
  route: RouteLocationNormalized,
): MoonRouteMeta | undefined => {
  if (
    route.meta === undefined ||
    !Array.isArray(route.meta?.requiredPermissions)
  ) {
    return undefined;
  }

  return route.meta as MoonRouteMeta;
};

/**
 * Priority order of pages to attempt to start a user who has just logged in on
 */
const landingPagePriorities = [
  RouteName.ORGANISATIONS,
  RouteName.USERS,
  RouteName.MY_LEARNING,
  RouteName.MY_PROFILE,
  RouteName.VR,
];

/**
 * Get the initial page for a user. This varies depending on a user's permissions.
 * Note, if a user hasn't been hydrated from local storage, this function should
 * always return the AUTH page
 * @param to
 */
const getLandingPageForUser = (to: RouteLocationNormalized): RouteMeta => {
  const fallbackRoute = { name: RouteName.AUTH };
  console.log("defaultRouteRedirect. To:", to.name);
  const routeMeta = getRouteMeta(to);
  if (routeMeta === undefined) {
    console.log("RouteMeta undefined");
    return fallbackRoute;
  }

  // If the user has never logged in before, navigate to the welcome page
  if (localStorage.getItem(USER_HAS_BEEN_ONBOARDED) == null) {
    return { name: RouteName.WELCOME };
  }
  const authStore = useAuthStore();

  for (const routeName of landingPagePriorities) {
    const routeMeta = routeNameToMeta(routeName);
    if (!routeRequiresAuth(routeMeta)) {
      return { name: routeName };
    }

    const userHasPermission = userHasPermissionToAccessAuthenticatedRoute(
      authStore.permissions,
      routeMeta,
    );
    if (userHasPermission) {
      return { name: routeName };
    }
    console.log(
      "User" + authStore.currentUser?.publicId + "does not have permission",
    );
  }

  return fallbackRoute;
};

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: DashboardView,
    // redirect done in `beforeRouter` call so that hydrated user permissions can be used to get an appropriate landing page
    meta: routeNameToMeta(RouteName.DASHBOARD),
    children: [
      {
        path: "organisations",
        name: RouteName.ORGANISATIONS,
        meta: routeNameToMeta(RouteName.ORGANISATIONS),
        component: () => import("../views/OrganisationsView.vue"),
        redirect: { name: RouteName.ORGANISATION_LIST },
        children: [
          {
            path: "",
            name: RouteName.ORGANISATION_LIST,
            meta: routeNameToMeta(RouteName.ORGANISATION_LIST),
            component: () => import("../views/OrganisationsListView.vue"),
          },
          {
            path: "new",
            name: RouteName.ORGANISATION_NEW,
            meta: routeNameToMeta(RouteName.ORGANISATION_NEW),
            component: () => import("../views/OrganisationsNewView.vue"),
          },
          {
            path: "scenariotrees",
            name: RouteName.SCENARIO_TREES,
            meta: routeNameToMeta(RouteName.SCENARIO_TREES),
            component: () => import("../views/ScenarioTreesView.vue"),
          },
          {
            path: "suiteState",
            name: RouteName.SUITE_STATE,
            meta: routeNameToMeta(RouteName.SUITE_STATE),
            component: () => import("../views/SuiteStateView.vue"),
            children: [
              {
                path: "",
                name: RouteName.SUITE_STATE_LIST,
                meta: routeNameToMeta(RouteName.SUITE_STATE_LIST),
                component: () => import("../views/SuiteStateListView.vue"),
              },
              {
                path: "update",
                name: RouteName.SUITE_STATE_UPDATE,
                meta: routeNameToMeta(RouteName.SUITE_STATE_UPDATE),
                component: () => import("../views/SuiteStateUpdateView.vue"),
              },
            ],
          },
          {
            path: ":orgId",
            name: RouteName.ORGANISATION_EDIT,
            meta: routeNameToMeta(RouteName.ORGANISATION_EDIT),
            component: () => import("../views/OrganisationsEditView.vue"),
          },
          {
            path: "/audit",
            name: RouteName.AUDIT,
            meta: routeNameToMeta(RouteName.AUDIT),
            component: () => import("../views/AuditView.vue"),
          },
        ],
      },
      {
        path: "users/:userId",
        name: RouteName.USER,
        meta: routeNameToMeta(RouteName.USER),
        component: () => import("../views/UserView.vue"),
      },
      {
        path: "/mylearning",
        name: RouteName.MY_LEARNING,
        meta: routeNameToMeta(RouteName.MY_LEARNING),
        component: () => import("../views/UserView.vue"),
      },
      {
        path: "users/:userId/submitscore",
        name: RouteName.USER_SUBMIT_SCORE,
        meta: routeNameToMeta(RouteName.USER_SUBMIT_SCORE),
        component: () => import("../views/UserSubmitScoreView.vue"),
      },
      {
        path: "users",
        name: RouteName.USERS,
        meta: routeNameToMeta(RouteName.USERS),
        component: () => import("../views/UsersView.vue"),
      },
      {
        path: "users/add",
        name: RouteName.ADD_USERS,
        meta: routeNameToMeta(RouteName.ADD_USERS),
        component: () => import("../views/AddUsersView.vue"),
      },
      {
        path: "/trendsanalytics",
        name: RouteName.TRENDS_ANALYTICS,
        meta: routeNameToMeta(RouteName.TRENDS_ANALYTICS),
        component: () => import("../views/TrendsAnalyticsView.vue"),
      },
      {
        path: "/analytics",
        name: RouteName.ANALYTICS,
        meta: routeNameToMeta(RouteName.ANALYTICS),
        component: () => import("../views/AnalyticsView.vue"),
      },
      {
        path: "/learningmaterials",
        name: RouteName.LEARNING_MATERIALS,
        meta: routeNameToMeta(RouteName.LEARNING_MATERIALS),
        component: () => import("../views/LearningMaterialsView.vue"),
      },
      {
        path: "/reportgenerator",
        name: RouteName.REPORT_GENERATOR,
        meta: routeNameToMeta(RouteName.REPORT_GENERATOR),
        component: () => import("../views/ReportGeneratorView.vue"),
      },
      {
        path: "myprofile",
        name: RouteName.MY_PROFILE,
        meta: routeNameToMeta(RouteName.MY_PROFILE),
        component: () => import("../views/MyProfileView.vue"),
      },
      {
        path: "vr",
        name: RouteName.VR,
        meta: routeNameToMeta(RouteName.VR),
        component: () => import("../views/VRView.vue"),
      },
      {
        path: "leaderboard",
        name: RouteName.LEADERBOARD,
        meta: routeNameToMeta(RouteName.LEADERBOARD),
        component: () => import("../views/LeaderboardView.vue"),
      },
      {
        path: "welcome",
        name: RouteName.WELCOME,
        meta: routeNameToMeta(RouteName.WELCOME),
        component: () => import("../views/WelcomeView.vue"),
      },
      {
        path: "moonboarding",
        name: RouteName.MOONBOARDING,
        meta: routeNameToMeta(RouteName.MOONBOARDING),
        component: () => import("../views/MoonboardingView.vue"),
      },
      {
        path: "platformissues",
        name: RouteName.PLATFORM_ISSUES,
        meta: routeNameToMeta(RouteName.PLATFORM_ISSUES),
        component: () => import("../views/PlatformIssuesView.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: RouteName.AUTH,
    component: AuthView,
    meta: routeNameToMeta(RouteName.AUTH),
    children: [
      {
        path: "",
        name: RouteName.LOGIN,
        meta: routeNameToMeta(RouteName.LOGIN),
        component: () => import("../views/auth/LoginView.vue"),
      },
      {
        path: "onboard",
        name: RouteName.USER_ONBOARD,
        meta: routeNameToMeta(RouteName.USER_ONBOARD),
        component: () => import("../views/auth/UserOnboardView.vue"),
      },
      {
        path: "passwordresetrequest",
        name: RouteName.PASSWORD_RESET_REQUEST,
        meta: routeNameToMeta(RouteName.PASSWORD_RESET_REQUEST),
        component: () => import("../views/auth/PasswordResetRequestView.vue"),
      },
      {
        path: "passwordreset",
        name: RouteName.PASSWORD_RESET,
        meta: routeNameToMeta(RouteName.PASSWORD_RESET),
        component: () => import("../views/auth/PasswordResetView.vue"),
      },
    ],
  },
  {
    path: "/organisations/:orgId/login",
    name: RouteName.ORGANISATION_LOGIN,
    meta: routeNameToMeta(RouteName.ORGANISATION_LOGIN),
    component: () => import("../views/auth/OrganisationLoginView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: RouteName.NOT_FOUND,
    meta: routeNameToMeta(RouteName.NOT_FOUND),
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/publicshop",
    name: RouteName.PUBLIC_SHOP,
    meta: routeNameToMeta(RouteName.PUBLIC_SHOP),
    component: () => import("../views/PublicShopView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const toMeta = getRouteMeta(to);

  if (toMeta === undefined) {
    console.error("Route:", to.name, "incorrectly configured");
    return { name: RouteName.NOT_FOUND };
  }

  const requiresAuth = routeRequiresAuth(toMeta);
  console.log("Route requires auth:", requiresAuth);

  if (requiresAuth) {
    const authStore = useAuthStore();
    try {
      await authStore.hydrateCurrentUserFromStorage();
    } catch (e) {
      // Calling the refreshToken route may not return a successful promise
      console.warn("Error hydrating current user from storage", e);
      return { name: RouteName.LOGIN };
    }
    // We may want to remember the URL that we re-directed from
    if (!authStore.isAuthenticated) {
      console.log(
        "route requires authentication, user is NOT currently authenticated",
      );
      return { name: RouteName.LOGIN };
    }
    console.log(
      "route requires authentication, user is currently authenticated",
    );

    // Check that the current user has the permissions required by the route
    const userHasRequiredPermissions =
      userHasPermissionToAccessAuthenticatedRoute(
        authStore.permissions,
        toMeta,
      );
    if (!userHasRequiredPermissions) {
      console.log("Current permissions:", authStore.permissions);
      console.log("Required permissions:", toMeta.requiredPermissions);
      return { name: RouteName.NOT_FOUND };
    }
  }

  // Note, not using a route `redirect` for this, as we want to hydrate the user before checking
  // their permissions to get the right landing page
  if (to.name === RouteName.DASHBOARD) {
    return getLandingPageForUser(to);
  }
});

export { routes };

export default router;
